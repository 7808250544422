import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/main.jpg";
import thumbnailEvent from "../../static/images/feature-uniqueqr.png"
import thumbnailBoard from "../../static/images/feature-marketing.png"
import thumbnailNews from "../../static/images/feature-tracking.png"
import thumbnailTeams from "../../static/images/feature-newsletters.png"
import thumbnailStaff from "../../static/images/feature-user.png"

const IndexPage = () => (
    <Layout>
        <SEO title="Get your business moving"/>

        <div className={"page-header home"}>
            <h1>Protect your business from fake products</h1>
            <p>We provide a one stop online solution to generate QR Code to ensure product authenticity<br/>and bring value to your business.</p>
            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailEvent}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Create unique QR code</h2>
                                <p>Through our system, you can generate or design your own unique QR code and generate them. All of them are tracked using our advance algorithm and each QR code are unique.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Mobile Marketing</h2>
                                <p>QRNow platform has built in smart and interactive Marketing campaigns in a few clicks. <br/> It is flexibility and customizable to your different customers' diverse needs<br/> </p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Board"} src={thumbnailBoard}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"News"} src={thumbnailNews}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Keep track of your product</h2>
                                <p>Our platform provides cutting edge technology to help you trace your products and verify the history and location of your products. Right from raw materials, manufacturing to stocking it out, you can get all the information you need!.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Newsletters</h2>
                                <p>QRNow also allows send newsletters to your target audience easily.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Team"} src={thumbnailTeams}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Sign up for free</h2>
                    <p>Sign up and start protecting of your business with QRNow.</p>
                </div>

                <div className={"button"}>
                    <a href="https://app.qrnow.org" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
